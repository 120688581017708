.sanitized-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted #4A5568;
}

.sanitized-tooltip .sanitized-tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #4A5568;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
}

.sanitized-tooltip:hover .sanitized-tooltiptext {
    visibility: visible;
}